<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create collaborators')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'researcher.administration.collaborators.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'collaborators'"
              :total="itemsTotal"
              :fetch-path="'collaborators/fetch'"
              :filter-path="'collaborators/filter'"
              :export-path="'collaborators/export'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        v-if="hasPermission('update collaborators')"
                        :href="$router.resolve({ name: 'researcher.administration.collaborators.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete collaborators')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view collaborators')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Name</label>
                  <input
                    v-model="filters.name"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Name: ${$event.target.value}`, value: $event.target.value }, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Email</label>
                  <input
                    v-model="filters.email"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Email: ${$event.target.value}`, value: $event.target.value }, 'email')"
                  >
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import 'vue2-datepicker/index.css'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Name',
          checked: true,
          order: 2,
        },
        {
          name: 'Surname',
          checked: true,
          order: 3,
        },
        {
          name: 'Email',
          checked: true,
          order: 4,
        },
        {
          name: 'Created at',
          checked: true,
          order: 5,
        },
        {
          name: 'Active',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        Name: 'first_name',
        Surname: 'last_name',
        Email: 'email',
        Active: 'active',
        'Created at': 'created_at',
      },
      title: 'Collaborators',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      items: 'collaborators/items',
      itemsTotal: 'collaborators/itemsTotal',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'collaborators')
    await this.$store.dispatch('collaborators/filter', this.filters)

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'collaborators',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'collaborators', url: `${Vue.prototype.$groupUrl}/collaborators/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
